import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    //
    // lending page routers
    //
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "lend-pages" */ '../views/lendPages/HomeView.vue')
  },
  {
    path: '/home',
    redirect: { name: 'home' }
  },
  {
    path: '/registration',
    name: 'registration',
    component: () => import(/* webpackChunkName: "lend-pages" */ '../views/lendPages/RegistrationView.vue')
  },
  {
    path: '/reg',
    redirect: { name: 'registration' }
  },
  {
    path: '/login/:href?',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth-pages" */ '../views/authPages/LoginView.vue')
  },
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: () => import('../views/ServicePages/EmailConfirmView.vue')
  },
    //
    // Docs routes
    //
  {
    path: '/docs',
    name: 'docs',
    //component: () => import(/* webpackChunkName: "lend-pages" */ '../views/DocsView.vue')
    redirect: { name: 'notFound' }
  },
    //
    // Dashboard routes
    //
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "dashboard-pages" */ '../views/dashboardPages/ProfileView.vue')
  },
  {
    path: '/my',
    redirect: { name: 'profile' }
  },
    //
    // Information files
    //
  {
    path: '/license',
    name: 'license',
    redirect: { name: 'notFound' }
  },
  {
    path: '/term-of-use',
    name: 'terms-of-use',
    redirect: { name: 'notFound' }
  },
  {
    path: '/policy',
    name: 'policy',
    redirect: { name: 'notFound' }
  },
  {
    path: '/offer',
    name: 'offer',
    redirect: { name: 'notFound' }
  },
  {
    path: '/about',
    name: "about",
    redirect: { name: 'notFound' }
  },
    //
    // OAuth 2.0 routes
    //
  {
    path: '/auth/v1/:service/',
    component: () => import(/* webpackChunkName: "auth-pages" */ '../views/authPages/OAuthView.vue')
  },
    //
    // Services routes
    //
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('../views/ServicePages/NotFoundView.vue')
  },
  {
    path: '/404',
    redirect: { name: 'notFound' }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
